
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { Chart } from '@/libs/chart-lib';
import { createChart } from '../chart-configs/loss-severity-chart-config';
import {
  LossSeverityModule,
  LossSeverityPlotItem,
  LossSeverityRiskGroup,
  LossTypes,
} from '@/store/modules/company-tabs/loss-severity';
import {
  formatCurrencyV2,
  getWeightedNpvId,
  LossSeverityBgColorScheme,
  LossSeverityColorScheme,
} from '../utils';

@Component
export default class LossSeverityChart extends Vue {
  @Prop() severityRange!: [number, number];
  @Prop() selectedColumn!: LossSeverityRiskGroup;

  private chart?: Chart;
  private selectedRisk?: LossSeverityRiskGroup;
  private curMinMax?: number[];
  private setMinMax?: (min: number, max: number) => void;

  reduceLossType(item: { label: string; value: LossTypes }) {
    return item.value;
  }

  mounted() {
    const { chart, drag, setMinMaxWithGap } = createChart({
      container: this.$refs.chartContainer as HTMLElement,
      plots: this.chartPlots,
      currentId: this.currentRisk,
      initialRange: this.initialRange,
      rangeDataX: this.rangeData.x,
      uncoveredRangeDataX: this.uncoveredRangeData.x,
      transferableRangeDataX: this.transferableRangeData.x,
      retainedRangeDataX: this.retainedRangeData.x,
      rangeLabels: this.rangeData.labels,
      midLabel: this.getMidLabel(),
      enabledDragData: this.enabledSeriesRangeData,
      xAxisTextDecorator: (str) =>
        formatCurrencyV2(
          Math.abs(Number(str)),
          LossSeverityModule.currency,
          LossSeverityModule.unit
        ),
    });
    this.chart = chart;
    this.curMinMax = [this.initialRange[1], this.initialRange[0]];
    this.setMinMax = setMinMaxWithGap;
    this.showCurrentSeries();
    this.onRiskSelect();
    drag.onIndexUpdated.add((prevInd, curInd) => {
      const newRange = [...this.severityRange];
      newRange[prevInd] = curInd;
      this.onSeverityRangeChange(newRange as [number, number]);
    });
  }

  get zoomRanges() {
    const arr = LossSeverityModule.plots[this.currentRisk] || [];
    const first = arr[0].x;
    const last = arr[arr.length - 1].x;
    const xMinList = [];
    let cur = first;
    while (cur < last) {
      cur = cur * 1.1;
      xMinList.push(cur);
    }
    return xMinList;
  }

  zoom(value: number) {
    let next;
    if (value > 0) {
      next = this.zoomRanges.find((z) => {
        const min = this.curMinMax?.[0];
        if (!min) return false;
        return z > Math.abs(min);
      });
    } else {
      next = [...this.zoomRanges].reverse().find((z) => {
        const min = this.curMinMax?.[0];
        if (!min) return false;
        return z < Math.abs(min);
      });
    }
    if (this.setMinMax && this.curMinMax && next !== undefined) {
      this.curMinMax = [-next, this.curMinMax[1]];
      this.setMinMax(this.curMinMax[0], this.curMinMax[1]);
      this.chart?.refresh();
    }
  }

  activated() {
    window.dispatchEvent(new Event('resize'));
  }

  toggleSeries(id: LossSeverityRiskGroup, show: boolean) {
    if (!this.chart) return;

    const seriesCurve = this.chart.data.findSeriesById(`${id}_curve`);

    if (seriesCurve) {
      seriesCurve.setPlotsIds(...(show ? [`${id}_line_dashed`] : []));
      this.chart.seriesReDraw_Static(seriesCurve);
    }

    const seriesPointsY = this.chart.data.findSeriesById(`${id}_points_y`);
    if (seriesPointsY) {
      seriesPointsY.setPlotsIds(...(show ? [`${id}_point`] : []));
      this.chart.seriesReDraw_Static(seriesPointsY);
    }
  }

  showCurrentSeries() {
    if (!this.chart) return;

    const seriesCurve = this.chart.data.findSeriesById(`${this.currentRisk}_curve`);

    if (seriesCurve) {
      seriesCurve.setPlotsIds(`${this.currentRisk}_line`);
      this.chart.seriesReDraw_Static(seriesCurve);
    }

    const seriesPointsY = this.chart.data.findSeriesById(`${this.currentRisk}_points_y`);

    if (seriesPointsY) {
      seriesPointsY.setPlotsIds(`${this.currentRisk}_point`);
      this.chart.seriesReDraw_Static(seriesPointsY);
    }
  }

  get coefficient() {
    return LossSeverityModule.coefficient;
  }

  @Watch('coefficient')
  @Watch('selectedColumn')
  onRiskSelect() {
    this.selectedRisk = this.selectedColumn;
    this.updateMidLabel();
    Object.values(LossSeverityRiskGroup).forEach((risk) => {
      const isCurrent = risk === this.currentRisk;
      if (!isCurrent) {
        const isSelected = risk === this.selectedRisk;
        this.toggleSeries(risk, isSelected);
      }
    });
  }

  preparePlotData(plot: LossSeverityPlotItem[], withLabels?: boolean) {
    return [...plot].reverse().reduce(
      (acc, cur) => {
        acc.x.push(-cur.x);
        acc.y.push(cur.y);
        if (withLabels) {
          if (!acc.label_y) acc.label_y = [];
          acc.label_y.push(String(cur.y) + '%');
          if (!acc.label_x) acc.label_x = [];
          acc.label_x.push(formatCurrencyV2(cur.x, LossSeverityModule.currency, cur.xUnit));
          if (!acc.label_tt) acc.label_tt = [];
          acc.label_tt.push(
            formatCurrencyV2(cur.npv || 0, LossSeverityModule.currency, cur.npvUnit)
          );
        }
        return acc;
      },
      { x: [], y: [] } as {
        x: number[];
        y: number[];
        label_y?: string[];
        label_x?: string[];
        label_tt?: string[];
      }
    );
  }

  beforeDestroy() {
    delete this.chart;
  }

  get chartPlots() {
    return Object.values(LossSeverityRiskGroup)
      .map((risk) => ({
        curve: this.preparePlotData(LossSeverityModule.plots[risk] || []),
        points: this.preparePlotData(LossSeverityModule.plotsWithNPV[risk] || [], true),
        id: risk,
        color: LossSeverityColorScheme[risk],
        bgColor: LossSeverityBgColorScheme[risk],
      }))
      .reverse();
  }

  get rangeData() {
    const arr = LossSeverityModule.plots[this.currentRisk] || [];
    const indList = [...this.severityRange];
    return {
      x: indList.map((val) => -arr[val].x),
      labels: indList.map((val) =>
        formatCurrencyV2(arr[val].x, LossSeverityModule.currency, arr[val].xUnit)
      ),
    };
  }

  get initialRange() {
    const arr = LossSeverityModule.plots[this.currentRisk] || [];
    const indList = [LossSeverityModule.startRangeInds[0], LossSeverityModule.startRangeInds[1]];
    return indList.map((val) => -arr[val].x);
  }

  get transferableValue() {
    const value = this.rangeData.x[0] - this.rangeData.x[1];
    return (
      (value && formatCurrencyV2(value, LossSeverityModule.currency, LossSeverityModule.unit)) || ''
    );
  }

  get uncoveredRangeData() {
    const rightX = this.rangeData.x[this.rangeData.x.length - 1];
    const arr = (LossSeverityModule.plots[this.currentRisk] || [])
      .filter((el) => -el.x <= rightX)
      .map(({ x }) => -x);
    return {
      x: arr,
    };
  }

  get transferableRangeData() {
    const leftX = this.rangeData.x[this.rangeData.x.length - 1];
    const rightX = this.rangeData.x[0];
    const arr = (LossSeverityModule.plots[this.currentRisk] || [])
      .filter((el) => -el.x >= leftX && -el.x <= rightX)
      .map(({ x }) => -x);
    return {
      x: arr,
    };
  }

  get retainedRangeData() {
    const leftX = this.rangeData.x[0];
    const arr = (LossSeverityModule.plots[this.currentRisk] || [])
      .filter((el) => -el.x >= leftX)
      .map(({ x }) => -x);
    return {
      x: arr,
    };
  }

  get retainedValue() {
    const value =
      this.retainedRangeData.x[0] - this.retainedRangeData.x[this.retainedRangeData.x.length - 1];
    return (
      (value && formatCurrencyV2(value, LossSeverityModule.currency, LossSeverityModule.unit)) || ''
    );
  }

  get enabledSeriesRangeData() {
    return (LossSeverityModule.plots[this.currentRisk] || []).map((item) => {
      return {
        x: -item.x,
        y: 0,
        label: formatCurrencyV2(item.x, LossSeverityModule.currency, item.xUnit),
      };
    });
  }

  @Emit()
  onSeverityRangeChange(newRange: [number, number]) {
    return newRange;
  }

  @Watch('severityRange')
  onSeverityRangeChanged() {
    if (!this.chart) return;
    const seriesRange = this.chart.data.findSeriesById('range_series');
    if (seriesRange) {
      seriesRange.replaceSeriesData(
        [this.rangeData.x, this.rangeData.x.map(() => 0)],
        true,
        this.rangeData.labels
      );
    }

    const transferableSeriesRange = this.chart.data.findSeriesById('transferable_range_series');
    if (transferableSeriesRange) {
      transferableSeriesRange.replaceSeriesData(
        [this.transferableRangeData.x, this.transferableRangeData.x.map(() => 0)],
        true
      );
    }

    const uncoveredSeriesRange = this.chart.data.findSeriesById('uncovered_range_series');
    if (uncoveredSeriesRange) {
      uncoveredSeriesRange.replaceSeriesData(
        [this.uncoveredRangeData.x, this.uncoveredRangeData.x.map(() => 0)],
        true
      );
    }
    const retainedSeriesRange = this.chart.data.findSeriesById('retained_range_series');
    if (retainedSeriesRange) {
      retainedSeriesRange.replaceSeriesData(
        [this.retainedRangeData.x, this.retainedRangeData.x.map(() => 0)],
        true
      );
    }
    this.updateMidLabel();
  }

  get currentRisk() {
    return LossSeverityModule.currentRiskGroup || LossSeverityRiskGroup.Low;
  }

  get currentPlotData() {
    return LossSeverityModule.currentPlot || [];
  }

  get weightedNpv() {
    const companyId = this.$store.getters.companyId;
    return LossSeverityModule.weightedNpvMap.find(
      ({ id }) => id === getWeightedNpvId(companyId, this.severityRange)
    )?.data;
  }

  getMidLabel() {
    if (!this.weightedNpv) return '';
    const value = this.weightedNpv[this.selectedRisk || this.currentRisk];
    return (
      (value &&
        formatCurrencyV2(
          value.WeightedNPV * this.coefficient,
          LossSeverityModule.currency,
          value.Unit
        )) ||
      ''
    );
  }

  updateMidLabel() {
    if (!this.chart) return;
    const seriesMidLabel = this.chart.data.findSeriesById('severity-mid-label_series');
    if (seriesMidLabel) {
      seriesMidLabel.setPlotsIds(`${this.selectedRisk}_severity-mid-label`);
      seriesMidLabel.replaceSeriesData([this.rangeData.x, this.rangeData.x.map(() => 0)], true, [
        (this.weightedNpv && this.getMidLabel()) || '',
      ]);
    }
  }

  @Watch('weightedNpv')
  onWeightedNpvLoaded() {
    this.updateMidLabel();
  }
}
