
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import ClickOutside from 'vue-click-outside';
import {
  getRawValueByUnit,
  LossSeverityModule,
  LossSeverityPlotItem,
  LossSeverityUnit,
  LossTypes,
} from '@/store/modules/company-tabs/loss-severity';
import { formatCurrencyV2, getCurrencySymbol, UnitCaptionMap } from '../utils';

@Component({ directives: { ClickOutside } })
export default class LossCoefficientSelector extends Vue {
  public showDropDown = false;
  public preventClosing = false;
  public lossType: LossTypes = LossTypes.economic;
  public insurableCoefficient: number = LossSeverityModule.defaultInsurableLossCoefficient;
  public insurableUnit: LossSeverityUnit = LossSeverityModule.unit;

  get currentText() {
    switch (this.lossType) {
      case LossTypes.economic:
        return `Economic Loss ${this.economicTextValue ? `(${this.economicTextValue})` : ''}`;

      case LossTypes.insurable:
        return `MAX Insurable Loss ${
          this.insurableTextValue ? `(${this.insurableTextValue})` : ''
        }`;
    }
  }

  get maxEconomicLoss() {
    const plot = LossSeverityModule.plotsEconomic[LossSeverityModule.currentRiskGroup || 'Low'];
    return plot && plot[plot.length - 1];
  }

  get maxInsurableLoss() {
    return (
      this.maxEconomicLoss && this.adjustLossItem(this.maxEconomicLoss, this.insurableCoefficient)
    );
  }

  get economicTextValue() {
    if (!this.maxEconomicLoss) return '';
    return formatCurrencyV2(
      this.maxEconomicLoss.x,
      LossSeverityModule.currency,
      LossSeverityModule.unit
    );
  }

  get insurableTextValue() {
    if (!this.maxInsurableLoss) return '';
    return formatCurrencyV2(
      this.maxInsurableLoss.x,
      LossSeverityModule.currency,
      this.insurableUnit
    );
  }

  get currencySymbol() {
    return getCurrencySymbol(LossSeverityModule.currency);
  }

  adjustLossItem(item: LossSeverityPlotItem, coefficient: number): LossSeverityPlotItem {
    const del =
      getRawValueByUnit(1, LossSeverityModule.unit) / getRawValueByUnit(1, this.insurableUnit);
    return {
      ...item,
      x: item.x * coefficient * del,
      xRaw: item.xRaw * coefficient,
    };
  }

  toggleDropDown() {
    this.showDropDown = !this.showDropDown;
    this.preventClosing = true;
  }

  closeDropDown() {
    if (this.preventClosing) {
      this.preventClosing = false;
      return;
    }
    if (this.showDropDown) this.showDropDown = false;
  }

  switchToEconomic() {
    this.lossType = LossTypes.economic;
    LossSeverityModule.setLossCoefficient(1);
    this.closeDropDown();
  }

  switchToInsurable() {
    this.lossType = LossTypes.insurable;
    LossSeverityModule.setLossCoefficient(this.insurableCoefficient);
  }

  search(str: string) {
    const regex = /[+-]?\d+([\.\,]?\d+)?/g;
    const floats =
      str.match(regex)?.map(function (v) {
        return parseFloat(v.split(',').join('.'));
      }) || [];
    if (floats.length === 0) return [];
    return [LossSeverityUnit.mln].map((unit) => ({
      label: floats[0] + UnitCaptionMap[unit],
      coefficient: getRawValueByUnit(floats[0], unit) / (this.maxEconomicLoss?.xRaw || 1),
      unit,
    }));
  }

  getResultValue(result: any) {
    return result.label;
  }

  handleSubmit(result: any) {
    this.insurableCoefficient = result.coefficient;
    this.insurableUnit = result.unit;
    LossSeverityModule.setLossCoefficient(this.insurableCoefficient);
  }
}
