import { Drag } from '@/libs/chart-lib/classes/drag/Drag';
import { Chart, Ticks } from '@/libs/chart-lib/index';

const canvasPaddings = [30, 60, 40, 60];

type Points = {
  x: number[];
  y: number[];
  label_x?: string[];
  label_y?: string[];
  label_tt?: string[];
};

type PlotData = {
  curve: { x: number[]; y: number[] };
  points: Points;
  id: string;
  color: string;
  bgColor: string;
};

interface ChartData {
  container: HTMLElement;
  plots: PlotData[];
  rangeDataX: number[];
  initialRange: number[];
  uncoveredRangeDataX: number[];
  transferableRangeDataX: number[];
  retainedRangeDataX: number[];
  rangeLabels: string[];
  currentId: string;
  midLabel: string;
  xAxisTextDecorator: (str: string) => string;
  enabledDragData: Drag['enabledSeriesData'];
}

export function createChart({
  container,
  plots,
  currentId,
  rangeDataX,
  initialRange,
  uncoveredRangeDataX,
  transferableRangeDataX,
  retainedRangeDataX,
  rangeLabels,
  midLabel,
  xAxisTextDecorator,
  enabledDragData,
}: ChartData) {
  const chart: Chart = new Chart(container, [0, 1], [0, 1]);

  const { xAxis, yAxis } = chart;

  const GRID_STEPS = 16;

  // Y AXIS
  yAxis.display = true;
  yAxis.setOptions('start', 1, `#000000${'33'}`);
  yAxis.ticks.setOptions(true, 'midStep', GRID_STEPS);
  yAxis.ticks.settickDrawOptionsPartial({
    color: '#000000',
    linewidth: 1,
    tickSize: 6,
  });
  yAxis.ticks.label.setOptions(false);
  yAxis.grid.setOptions(true, `#000000${'0D'}`, 1, [1, 0]);

  // X AXIS
  xAxis.display = true;
  xAxis.setOptions('start', 1, `#000000${'33'}`);
  xAxis.ticks.setOptions(true, 'midStep', GRID_STEPS);
  xAxis.ticks.settickDrawOptionsPartial({
    color: '#000000',
    linewidth: 1,
    tickSize: -6,
  });
  xAxis.ticks.label.setOptions(false);
  xAxis.grid.setOptions(true, `#000000${'0D'}`, 1, [1, 0]);

  // plot & series
  const configPlot = (data: PlotData) => {
    const { id, color, bgColor, curve, points } = data;

    // plots
    chart.addPlot(`${id}_line`, 'curve_line', 3, color, []);
    chart.addPlot(`${id}_line_dashed`, 'curve_line', 2, color, [6, 6]);
    chart.addPlot(`${id}_point`, 'dotted', 4, '#fff', color, 6);

    // series
    chart.addSeries(`${id}_curve`, [curve.x, curve.y]);
    chart.addSeries(`${id}_points_y`, [points.x, points.y], points.label_y);

    // MID SEVERITY LABEL
    chart
      .addPlot(`${id}_severity-mid-label`, 'severity_mid_label')
      .setOptionsPartially({
        lineWidth: 1,
        lineColor: '#e6e6e6',
        brushColor: bgColor,
        opacity: 0.2,
        vpOffset: {
          x1: 0,
          y1: -20,
          x2: 0,
          y2: 0,
        },
      })
      .label.setOptions(true, color)
      .setCenterX(-0.5)
      .setFontOptions(21, 'JetBrains Mono', '400');
  };

  // config plots
  plots
    .filter(({ id }) => id === currentId)
    .forEach((plotData) => {
      configPlot(plotData);
    });

  // config plots
  plots
    .filter(({ id }) => id !== currentId)
    .forEach((plotData) => {
      configPlot(plotData);
    });

  // range plots & series
  const rangeVpOffset = {
    x1: 0,
    y1: 12,
    x2: 0,
    y2: 0,
  };
  chart.addPlot('range_area', 'line_area_vertical').setOptionsPartially({
    lineColor: 'transparent',
    brushColor: '#2c69f31a',
    vpOffset: rangeVpOffset,
  });
  chart
    .addSeries('transferable_range_series', [
      transferableRangeDataX,
      transferableRangeDataX.map(() => 0),
    ])
    .setPlotsIds('range_area');

  chart.addPlot('range_line', 'line_vertical').setOptionsPartially({
    lineColor: '#2C69F3',
    lineWidth: 2,
    vpOffset: rangeVpOffset,
  });
  chart.addPlot('drag_pin', 'drag_severity_pin').setOptionsPartially({
    lineColor: '#2860DE',
    lineWidth: 1,
    brushColor: '#2B68F1',
    mainSize: 3,
    size: {
      x: 6,
      y: 11,
    },
    vpOffset: rangeVpOffset,
  });
  // last plots
  chart
    .addPlot('range_point_values_x', 'side_text_bottom')
    .label.setOptions(true, '#F9F9F9', 'bottom', 17, [14, 'JetBrains Mono', '400'])
    .setCenterX(-0.5)
    .setBackgroundRectangle({
      border: {
        size: 1,
        color: '#285FDB',
      },
      padding: [8, 7, 8, 7],
      backgroundColor: '#2C69F3',
      radius: 8,
      offset: { x: 0, y: -1 },
    });
  chart.addPlot('range_line_triangles', 'line_triangles_vertical').setOptionsPartially({
    lineColor: '#2C69F3',
    brushColor: '#2C69F3',
    lineWidth: 1,
    mainSize: 12,
    vpOffset: rangeVpOffset,
  });
  const RangeSeries = chart
    .addSeries('range_series', [rangeDataX, rangeDataX.map(() => 0)], rangeLabels)
    .setPlotsIds('range_line', 'range_point_values_x', 'range_line_triangles', 'drag_pin');

  chart.addPlot('range_area_gray', 'line_area_vertical').setOptionsPartially({
    lineColor: 'transparent',
    brushColor: '#7b7b7b1a',
    vpOffset: rangeVpOffset,
  });
  chart
    .addSeries('uncovered_range_series', [uncoveredRangeDataX, uncoveredRangeDataX.map(() => 0)])
    .setPlotsIds('range_area_gray');

  chart.addPlot('range_area_purple', 'line_area_vertical').setOptionsPartially({
    lineColor: 'transparent',
    brushColor: '#5303fc26',
    vpOffset: rangeVpOffset,
  });
  chart
    .addSeries('retained_range_series', [retainedRangeDataX, retainedRangeDataX.map(() => 0)])
    .setPlotsIds('range_area_purple');

  const drag = new Drag(
    'default',
    chart.canvasTT,
    RangeSeries,
    xAxis,
    yAxis,
    enabledDragData
  ).setOptions({
    mainSize: 20,
    vpOffset: rangeVpOffset,
  });

  chart
    .addSeries(
      'severity-mid-label_series',
      [rangeDataX, rangeDataX.map(() => 0)],
      rangeDataX.map(() => midLabel)
    )
    .setPlotsIds(`${currentId}_severity-mid-label`);

  const LabelTickX = new Ticks(chart.xAxis.type);

  const setMinMaxWithGap = (min: number, max: number) => {
    const dx = Math.abs(max - min) / (2 * (GRID_STEPS - 1));
    chart.xAxis.setMinMaxStatic([min - dx, max + dx]);
    const xTickStep = Math.round(Math.abs(chart.xAxis.max - chart.xAxis.min) / 5);
    LabelTickX.setOptions(false, 'fixedStep', xTickStep);
  };

  // set ranges
  setMinMaxWithGap(initialRange[1], initialRange[0]);
  const yMinMax = chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max);

  // x ticks
  LabelTickX.label
    .setOptions(true, '#000', 'bottom', 17, [14, 'JetBrains Mono', '400'])
    .setCenterX(-0.5)
    .setTextDecorator(xAxisTextDecorator);
  xAxis.addCustomTicks(LabelTickX);

  // y ticks
  const yTickStep = Math.round(yMinMax[1] / 5);
  const LabelTickY = new Ticks(chart.yAxis.type);
  LabelTickY.setOptions(false, 'fixedStep', yTickStep);
  LabelTickY.label
    .setOptions(true, '#000', 'left', 14, [14, 'JetBrains Mono', '400'])
    .setCenterX(-1)
    .setTextDecorator((str) => Math.round(Math.abs(Number(str))) + '%');
  yAxis.addCustomTicks(LabelTickY);

  const dy = Math.abs(yMinMax[1] - yMinMax[0]) / (2 * (GRID_STEPS - 1));
  chart.yAxis.setMinMaxStatic([yMinMax[0] - dy, yMinMax[1] + dy]);
  chart.setCanvasPaddings(...canvasPaddings);
  chart.refresh();
  return { chart, drag, setMinMaxWithGap };
}
